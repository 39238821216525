<template>
  <div class="content">
    <a-row>
      <a-form layout="inline">
        <a-form-item label="查询时间">
          <a-range-picker v-model="query.time"/>
        </a-form-item>

        <a-form-item label="小区名称">
          <a-input v-model="query.centerName" placeholder="请输入小区名称"/>
        </a-form-item>

        <a-form-item label="街道">
          <a-select v-model="query.streetCode" placeholder="请选择街道" style="width: 200px">
            <a-select-option :key="-1" :value="undefined">全部</a-select-option>
            <a-select-option v-for="street in streetList" :key="street.value" :value="street.value">
              {{ street.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="只显示有数据小区">
          <a-select v-model="query.onlyHasData" placeholder="是/否" style="width: 200px">
            <a-select-option  key="1" :value="true">
              是
            </a-select-option>
            <a-select-option  key="2" :value="false">
              否
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="customQuery">查询</a-button>
        </a-form-item>

        <a-form-item style="color: red; font-weight: bolder;">
          默认查询前一天！
        </a-form-item>
      </a-form>
    </a-row>

    <a-row justify="end" type="flex">
      <a-col>
        <a-space>
          <a-button type="primary" @click="showNoDataVillage">查看无数据小区</a-button>
          <a-button type="primary" @click="$notification['warning']({ message: '敬请期待'})">下载该表</a-button>
        </a-space>
      </a-col>
    </a-row>

    <a-row style="margin-top: 24px;">
      <a-table :columns="columns" :data-source="data" :loading="loading"
               :pagination="pagination"
               @change="handleTableChange">
        <span slot="number" slot-scope="text, record, index">{{index  + 1 + (pagination.pageSize * (pagination.current - 1))}}</span>
        <span slot="kitchenWasteWeight" slot-scope="text">{{ text }}KG</span>
        <span slot="otherWasteWeight" slot-scope="text">{{ text }}KG</span>
        <span slot="recyclableWasteWeight" slot-scope="text">{{ text }}KG</span>
        <span slot="harmfulWasteWeight" slot-scope="text">{{ text }}KG</span>
        <span slot="kitchenWasteRate" slot-scope="text">{{ text }}%</span>
        <span slot="otherWasteRate" slot-scope="text">{{ text }}%</span>

        <span slot="action" slot-scope="text, record">
          <a-space>
            <a-button type="primary" @click="toVillageDetail(record)">详情</a-button>
            <a-button type="primary" @click="toCleanHouse(record)">清洁屋</a-button>
          </a-space>
        </span>
      </a-table>
    </a-row>

    <no-data-village ref="noDataVillage"/>
  </div>
</template>

<script>
import NoDataVillage from "./components/noDataVillage";
import {queryVillageAnalyze} from "@/api/village";
import _ from 'lodash'
import {parseNumberWith2Fixed} from "@/utils/transition";
import {queryRegionNodeChild} from "@/api/region";
import {trashType} from "@/common/config";
import {formatToDateBegin, formatToDateEnd, getPreviewDate} from "@/utils/time";
import {mapState} from "vuex";

export default {
  name: "villageAnalyze",
  components: {NoDataVillage},
  data() {
    return {
      query: {
        time: [],
        centerName: undefined,
        areaCode: undefined,
        streetCode: undefined,
        onlyHasData: false,
        sort: undefined,
        order: undefined
      },
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showTotal: (total, range) => `总共 ${total} 条数据`,
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'number',
          key: 'number',
          scopedSlots: {customRender: 'number'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '小区名称',
          dataIndex: 'villageName',
          key: 'villageName',
          align: 'center',
          // sorter: true,
        },
        {
          title: '街道',
          dataIndex: 'street',
          key: 'street',
          align: 'center',
          // sorter: true,
        },
        {
          title: '厨余垃圾',
          dataIndex: 'kitchenWasteWeight',
          key: 'kitchenWasteWeight',
          scopedSlots: {customRender: 'kitchenWasteWeight'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '其他垃圾',
          dataIndex: 'otherWasteWeight',
          key: 'otherWasteWeight',
          scopedSlots: {customRender: 'otherWasteWeight'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '可回收物',
          key: 'recyclableWasteWeight',
          dataIndex: 'recyclableWasteWeight',
          scopedSlots: {customRender: 'recyclableWasteWeight'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '有害垃圾',
          key: 'harmfulWasteWeight',
          dataIndex: 'harmfulWasteWeight',
          scopedSlots: {customRender: 'harmfulWasteWeight'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '厨余垃圾分出率',
          key: 'kitchenWasteRate',
          dataIndex: 'kitchenWasteRate',
          scopedSlots: {customRender: 'kitchenWasteRate'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '其他垃圾分出率',
          key: 'otherWasteRate',
          dataIndex: 'otherWasteRate',
          scopedSlots: {customRender: 'otherWasteRate'},
          align: 'center',
          // sorter: true,
        },
        {
          title: '查看',
          key: 'action',
          scopedSlots: {customRender: 'action'},
          align: 'center'
        },
      ],
      data: [],
      loading: true,
    }
  },
  mounted() {
    this.query.time = getPreviewDate(1, 1)
    this.queryVillageList()
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order === 'ascend' ? 'asc' : 'desc'

      this.queryVillageList()
    },
    async queryVillageList() {
      this.loading = true

      let data = await queryVillageAnalyze(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    customQuery() {
      this.pagination.current = 1
      this.queryVillageList()
    },
    showNoDataVillage() {
      this.$refs.noDataVillage.toggleVisible()
    },
    toVillageDetail(village) {
      this.$router.push({
        name: 'villageDetail',
        query: {
          villageName: village.villageName,
          villageCode: village.key
        }
      })
    },
    toCleanHouse(village) {
      this.$router.push({
        name: 'cleanHouseList',
        query: {
          villageName: village.villageName,
          villageCode: village.key
        }
      })
    },
    convertQuery() {
      return {
        startTime: formatToDateBegin(this.query.time[0]),
        endTime: formatToDateBegin(this.query.time[1]),
        streetCode: this.query.streetCode,
        centerName: this.query.centerName,
        onlyHasData: this.query.onlyHasData,
        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertData(data) {
      return _.map(data, value => {
        let tmp = {
          key: value.centerCode,
          villageName: value.centerName,
          street: value.streetName,
        }

        _.forEach(trashType, trash => {
          tmp[`${trash.eng}Weight`] = parseNumberWith2Fixed(value[`${trash.eng2}`])
          tmp[`${trash.eng}Rate`] = value[`${trash.eng2}Percent`]
        })

        return tmp
      })
    },
    convertPagination(data) {
      this.pagination.current = data.pageNo + 1
      this.pagination.total = data.totalElements
    }
  },
  computed: mapState({
    streetList: state => state.user.streetList
  })
}
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;
}
</style>
